import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/sectionContainer'
import Header from '../components/header'
import Footer from '../components/footer'
import { H2, H3 } from '../components/headings'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Appes - Meant to evolve" />
    <Header />
    <Section style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  paddingTop: 200, paddingBottom: 200 }}>
      <H2 style={{ textAlign: "center" }}>Page not found <span role="img" aria-label="mind-blown">🤯</span></H2>
      <H3 style={{ textAlign: "center"}}>Don't worry, you can go back to the home page if you click <Link to="/" style={{ color: '#E23C30' }}>here</Link>.</H3>
    </Section>
    <Footer />
  </Layout>
)

export default NotFoundPage
